<template>
    <div>
        <v-container>
          <v-card style="background-color: #F5F5F5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                <span v-if="isNew">Nueva</span>
                <span v-if="!isNew">Editar</span>
                incidencia
            </div>

            <v-form
                ref="form"
                v-model="formValid"
                autocomplete="off">
            <!-- FORMULARIO INCIDENCIA -->
            <v-row class="px-7 mt-4">
                <v-col v-if="!isNew" cols="7" sm="5" md="3" lg="3" xl="2">
                    <v-text-field
                        class="mb-n7" outlined dense text
                        readonly
                        :value="inc.IncId"
                        label="Identificador">
                    </v-text-field>
                </v-col>
                <v-col cols="7" sm="5" md="3" lg="3" xl="2">
                    <v-text-field
                        class="mb-n7" outlined dense text
                        readonly
                        :value="formatDate(inc.IncTmp)"
                        label="Fecha">
                    </v-text-field>
                </v-col>
                <v-col v-if="!isNew" cols="7" sm="5" md="3" lg="3" xl="2">
                    <v-text-field
                        class="mb-n7" outlined dense text
                        readonly
                        :value="formatDate(inc.IncStaTmp)"
                        label="Fecha de estado">
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="px-7 mt-2">
                <v-col v-if="(perIdAux == null || perIdAux === 0) && !(esEmpleado && !esDirDep) && !isProveedor" cols="12" sm="8" md="6" lg="6" xl="4">
                    <PerFind class="mb-n7" ref="perFind" v-model="inc.Per" label="Creada por" :perCatIdAnyLevel="101"></PerFind>
                </v-col>
                <v-col v-if="!esTienda" cols="12" sm="8" md="6" lg="6" xl="4">
                    <DirFind class="mb-n7" ref="dirFind" :readonly="isProveedor || !esPerMantenimiento" v-model="inc.Dir" label="Tienda"></DirFind>
                </v-col>
                <!-- DATOS RESPONSABLE - INICIO -->
                <v-col v-if="esDepartamento || esDirDep" cols="12" sm="8" md="6" lg="6" xl="4">
                    <PerFind class="mb-n7" ref="perAsigFind" v-model="inc.PerAsig" label="Asignada a" :perCatIdAnyLevel="101"></PerFind>
                </v-col>
            </v-row>
            <v-row class="px-7 mt-2">
                  <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                      <DateFind clearable label="Fecha fin estimada" :readonly="isProveedor || isComercial" v-model="inc.IncTmpEst"></DateFind>
                  </v-col>
                <template v-if="esDepartamento || esDirDep">
                    <v-col class="mb-n7" cols="12" sm="5" md="3" lg="3" xl="2">
                        <DateFind clearable label="Fecha comprometida entrega" v-model="inc.IncTmpComp"></DateFind>
                    </v-col>
                    <v-col cols="12" sm="5" md="3" lg="3" xl="2">
                        <v-text-field
                            outlined dense
                            hide-details
                            type="number"
                            step="0.25"
                            min="0"
                            v-model="inc.IncHorasEst"
                            label="Horas estimadas">
                        </v-text-field>
                    </v-col>
                </template>
            </v-row>
            <!-- DATOS RESPONSABLE - FIN -->
            <v-row class="px-7 mt-2">
                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                    <IncCatFind class="mb-n7" ref="incCatFind" 
                        v-model="inc.Tipo" 
                        label="Tipo" 
                        :readonly="isProveedor"
                        :showParentsOnly="true"
                        @change="onChangeTipo">
                    </IncCatFind>
                </v-col>
                <v-col cols="12" sm="7" md="5" lg="5" xl="3">
                    <IncCatFind class="mb-n7" ref="incCatFind"
                        v-model="inc.IncCat" 
                        label="Categoría"
                        :readonly="inc.Tipo == null || inc.Tipo.IncCatId == null || isProveedor"
                        :incCatIdParent="inc.Tipo != null ? inc.Tipo.IncCatId : null">
                    </IncCatFind>
                </v-col>
            </v-row>
            <v-row class="px-7 mt-2">
                <v-col cols="12" sm="5" md="4" lg="3" xl="2">
                    <PriorFind class="mb-n7" ref="priorFind" :readonly="isProveedor" v-model="inc.Prior" label="Prioridad"></PriorFind>
                </v-col>
                <v-col  v-if="esDepartamento || esDirDep" cols="12" sm="5" md="3" lg="3" xl="2">
                    <v-text-field
                        outlined dense
                        hide-details
                        type="number"
                        min="0"
                        v-model="inc.IncPriorOrden"
                        label="Orden de prioridad"
                        @change="onChangeOrden">
                    </v-text-field>
                </v-col>
                <v-col v-if="!isNew" cols="12" sm="5" md="4" lg="3" xl="2">
                    <IncStaFind class="mb-n7" ref="incStaFind" v-model="inc.IncSta" label="Estado"></IncStaFind>
                </v-col>
                <v-col v-if="!isProveedor" cols="12" sm="8" md="6" lg="6" xl="4">
                    <PerFind class="mb-n7" ref="perProv" v-model="inc.PerProveedor" :readonly="!esPerMantenimiento" label="Proveedor" :perCatIdAnyLevel="104"></PerFind>
                </v-col>
            </v-row>
            <v-row class="px-7 mt-2">
                <v-col cols="12" sm="10" md="6" lg="6" xl="5">
                    <v-textarea
                        class="mt-0 mb-n7"
                        outlined
                        auto-grow
                        v-model="inc.IncDescrip"
                        required
                        :rules="[v => !!v || 'La descripción es obligatoria']"
                        label="Descripción">
                    </v-textarea>
                </v-col>
            </v-row>
            
            <v-row v-if="!isNew" class="px-7 mt-2">
                <v-col v-if="ultimaRespuesta !== ''" cols="12" sm="10" md="6" lg="6" xl="5">
                    <v-textarea
                        class="mt-0 mb-n7"
                        outlined
                        auto-grow
                        readonly
                        v-model="ultimaRespuesta"
                        label="Última respuesta">
                    </v-textarea>
                </v-col>
                <v-col cols="12" sm="10" md="6" lg="6" xl="5">
                    <v-textarea
                        class="mt-0 mb-n7"
                        outlined
                        auto-grow
                        v-model="respuesta"
                        label="Respuesta">
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row v-if="!isNew" class="mx-4">
                <v-col class="mt-2" cols="12">
                    <v-data-table
                        :headers="headers"
                        :items="dataRows"
                        :options.sync="options"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :loading="loading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1"
                        dense>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.IncHisTmp="{ item }">
                            {{ formatDate(item.IncHisTmp) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-row class="mx-4">
                <v-btn  v-if="esPerMantenimiento || isProveedor"
                @click="onBtnShowEditMaterial(null, true)"
                color="primary"
                class="mt-5 mb-3 mr-2">
                Nuevo
                </v-btn>
                <v-col class="mt-2" cols="12">
                    <v-data-table
                        :headers="headersMaterial"
                        :items="dataRowsMaterial"
                        :options.sync="optionsMaterial"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :loading="loading"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1"
                        dense>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Tipo="{ item }">
                            {{getTipoName(item.Tipo)}}
                        </template>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.ArticuloNombre="{ item }">
                            <template v-if="item.Tipo == 0">
                              {{item.ArticuloNombre}}
                            </template>
                            <template v-else>
                              {{item.Observaciones}}
                            </template>
                        </template>
                        <!-- eslint-disable-next-line vue/valid-v-slot -->
                        <template v-slot:item.Actions="{ item }">
                            <v-btn
                            color="primary" class="mr-2 my-1" small
                            :disabled="isProveedor && item.Tipo != 1"
                            @click.stop="onBtnShowEditMaterial(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                            color="error" class="my-1" small
                            :disabled="isProveedor && item.Tipo != 1"
                            @click.stop="onBtnShowDeleteMaterial(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px">
                    Aceptar
                </v-btn>
                
                <v-btn
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                    @click="goToList">
                    Cancelar
                </v-btn>

                <v-btn v-if="!isNew"
                    color="error"
                    class="mt-2 mb-4"
                    style="width:100px"
                    @click="onBtnShowDeleteModal()">
                    Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales -->
        <!-- Borrar incidencia -->
        <v-dialog
            v-model="showDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR INCIDENCIA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la incidencia? Esta acción no se podrá deshacer.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      color="primary"
                      @click="onBtnDelete">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelDelete">
                      Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Materiales -->
        <v-dialog
            v-model="showMaterialDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{materialDialogTitle}}
                </v-card-title>

                <v-card-text class="mt-2">
                    <template v-if="materialDialogDeleteMode">
                    ¿Desea borrar el material? Esta acción no se podrá deshacer.
                    </template>
                    <v-row v-else-if="materialSelected.item != null">
                      <v-col class="px-7" cols="12">
                          <v-radio-group v-model="materialSelected.tipo" :disabled="isProveedor" @change="materialSelected.observaciones = ''; materialSelected.mat = null" row>
                              <v-radio v-for="opt in tipoOptions" :key="opt.value"
                                  :label="opt.label" :value="opt.value"
                              ></v-radio>
                          </v-radio-group>
                      </v-col>
                      <v-col v-if="materialSelected.tipo==0" class="px-7" cols="12">
                          <ArtFind class="mb-n7" ref="matCtrl" v-model="materialSelected.mat" label="Material"></ArtFind>
                      </v-col>
                      <v-col v-else-if="materialSelected.tipo==1" class="px-7" cols="12">
                          <v-text-field
                              class="mt-0 mb-n7" outlined
                              v-model="materialSelected.observaciones"
                              label="Concepto">
                          </v-text-field>
                      </v-col>
                      <v-col class="px-7" cols="6">
                          <v-text-field type="number"
                              class="mt-0 mb-n7" outlined
                              v-model="materialSelected.cantidad"
                              label="Cantidad">
                          </v-text-field>
                      </v-col>
                      <v-col class="px-7" cols="6">
                          <v-text-field type="number"
                              class="mt-0 mb-n7" outlined
                              v-model="materialSelected.importe"
                              label="Importe">
                          </v-text-field>
                      </v-col>
                      
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="materialDialogDeleteMode"
                    :loading="loading"
                      color="primary"
                      @click="onBtnDeleteMaterial">
                      Aceptar
                  </v-btn>
                  <v-btn v-else
                      :loading="loading"
                      color="primary"
                      :disabled="disabledSaveMaterial"
                      @click="onBtnSaveMaterial">
                      Aceptar
                  </v-btn>
                  <v-btn @click="onBtnCancelMaterialDialog">
                      Cancelar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../../../components/DateFind.vue";
import DirFind from "../../../components/DirFind.vue";
import IncCatFind from "../../../components/IncCatFind.vue";
import IncStaFind from "../../../components/IncStaFind.vue";
import PerFind from "../../../components/PerFind.vue";
import PriorFind from "../../../components/PriorFind.vue";
import ArtFind from "../../../components/ArtFind.vue";
export default ({
    components: {
        DateFind, DirFind, IncCatFind, IncStaFind, PerFind, PriorFind, ArtFind
    },
    computed: {
        ...mapState(['urlRaiz', 'empId', 'perId', 'perIdAux', 'nombreUsuario', 'nombreUsuarioAux', 'esPartner', 'esPerMantenimiento', 'esDirector', 'esResZona', 'esTienda', 'esEmpleado', 'esDepartamento', 'esDirDep']),
        materialDialogTitle: function () {
          if (this.materialDialogDeleteMode) return 'BORRAR MATERIAL';
          if (this.materialSelected.index > 0) return 'EDITAR MATERIAL';
          return 'NUEVO MATERIAL';
        },
        disabledSaveMaterial: function() {
          if (this.materialSelected == null) return true;
          return (this.materialSelected.mat == null && 
          (this.materialSelected.observaciones == null || this.materialSelected.observaciones == ''))
        },
        isComercial: function() {
          return this.esResZona || this.esDirector;
        }
    },
    data () {
        return {
            loading: false,
            isNew: true,
            isProveedor: false,
            isValid: false,
            formValid: false,
            showDeleteDialog: false,
            tipoOptions: [
                { label: 'MATERIAL', value: 0 },
                { label: 'CONCEPTO', value: 1 }
            ],
            inc: {
                IncId: 0,
                IncTmp: null,
                Dir: {},
                Per: {},
                Prior: {},
                IncSta: {},
                Tipo: null,
                IncCat: {},
                IncDescrip: '',
                IncRespuesta: '',
                IncHis: [],
                PerAsig: {},
                IncTmpEst: null,
                IncTmpComp: null,
                IncHorasEst: null,
                IncPriorOrden: null
            },
            respuesta: '',
            ultimaRespuesta: '',
            // IncHis table
            dataRows: [],
            options: {itemsPerPage: 100},
            headers: [
                { text: 'Fecha', width:145, sortable: false, value: 'IncHisTmp' },
                { text: 'Persona', width:225, sortable: false, value: 'Per.PerNom' },
                { text: 'Categoría', width:200, sortable: false, value: 'IncCat.IncCatNom' },
                { text: 'Estado', width:112, sortable: false, value: 'IncSta.IncStaNom' },
                { text: 'Respuesta', width:275, sortable: false, value: 'IncHisRespuesta' }
            ],
            // Incmateriales table
            dataRowsMaterial: [],
            optionsMaterial: {itemsPerPage: 100},
            headersMaterial: [
                { text: 'Tipo', width:100, sortable: false, value: 'Tipo' },
                { text: 'Nombre', width:250, sortable: false, value: 'ArticuloNombre' },
                { text: 'Cantidad', width:200, sortable: false, value: 'Cantidad' },
                { text: 'Importe', width:200, sortable: false, value: 'Importe' },
                { text: 'Acciones', width:100, sortable: false, align: 'end', value: 'Actions' }
            ],
            // Editar material dialog
            showMaterialDialog: false,
            materialDialogDeleteMode: false,
            materialSelected: {
              index: -1,
              tipo: null,
              mat: null,
              observaciones: '',
              item: null
            }
        }
    },
    watch: {
      inc: {
        handler () {
            this.isValid = this.validate(false);
        },
        deep: true,
      }
    },
    mounted() {
      this.isProveedor = this.$route.meta.isProveedor;
      if (this.isProveedor && !this.esPartner) { this.$router.push('/'); return; }
      if (!this.isProveedor && !this.esPerMantenimiento && !this.esDirector && !this.esResZona) { this.$router.push('/'); return; }
      this.isNew = this.$route.meta.isNew;
      
      // Dir Init.
      const dir = this.$store.state.dir;
      if(dir != null && dir.DirId != null && dir.DirId != 0) {
        this.inc.DirId = dir.DirId;
        this.inc.Dir = {
          DirId: dir.DirId,
          DirNom: dir.DirNom
        }
      }

      if(!this.isNew) {
        this.inc.IncId = this.$route.params.id;
        this.loadData();
      } else {
        this.inc.IncTmp = new Date();
        // Per init.
        if (this.esEmpleado)
        {
          if(!this.esDirDep)
          {
            this.inc.PerId = this.perId;
            this.inc.Per = {
                PerId: this.perId,
                PerNom: this.nombreUsuario
            }
          }
        }
        else if(this.perIdAux != null && this.perIdAux != 0) {
          this.inc.PerId = this.perIdAux;
          this.inc.Per = {
              PerId: this.perIdAux,
              PerNom: this.nombreUsuarioAux
          }
        }
      }
    },
    methods: {
      goToList() {
        const url = this.isProveedor ? '/proveedor/incidencias' : '/mantenimiento/incidencias';
        this.$router.push(url);
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_INC',
            IncId: this.inc.IncId,
            DirId: this.inc.DirId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Inc;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la incidencia.');
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.inc.DirId = this.inc.Dir != null ? this.inc.Dir.DirId : null;
        this.inc.PerId = this.inc.Per != null ? this.inc.Per.PerId : null;
        this.inc.PerIdAsig = this.inc.PerAsig != null ? this.inc.PerAsig.PerId : null;
        this.inc.PerIdProveedor = this.inc.PerProveedor != null ? this.inc.PerProveedor.PerId : null;
        this.inc.IncCatId = this.inc.IncCat != null ? this.inc.IncCat.IncCatId : null;
        this.inc.PriorId = this.inc.Prior != null ? this.inc.Prior.PriorId : null;
        this.inc.IncStaId = this.inc.IncSta != null ? this.inc.IncSta.IncStaId : null;
        this.inc.IncHis = [];

        let incHisPerId = 0;
        if (this.esEmpleado || this.perIdAux == null || this.perIdAux === 0)
        {
            incHisPerId = this.perId;
        }
        else 
        {
            incHisPerId = this.perIdAux;
        }
        this.inc.IncRespuesta = this.respuesta;

        const IncArticulos = this.dataRowsMaterial.map(m => { 
          return {
            IncArticuloId: m.IncArticuloId, ArticuloId: m.ArticuloId, ArticuloNombre: m.ArticuloNombre, Observaciones: m.Observaciones, Tipo: m.Tipo, Cantidad: m.Cantidad, Importe: m.Importe
          };
        });

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Inc: this.inc,
            EmpId: this.empId,
            IncHisPerId: incHisPerId,
            IncArticulos: IncArticulos,
            Desarrollo: this.$store.state.desarrollo
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar la incidencia.');
            }
          });
        })
      },
      deleteDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            IncId: this.inc.IncId,
            EmpId: this.empId,
            Desarrollo: this.$store.state.desarrollo
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/inc", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al borrar la incidencia.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert('Incidencia no encontrada.');
              this.goToList();
              return;
            }

            // Init. dates
            if(data.item.IncTmpEst != null)
            {
              const dateEst = new Date(data.item.IncTmpEst);
              const dateStringEst = dateEst.getFullYear() + '-' + 
              String(dateEst.getMonth() + 1).padStart(2, '0') + '-' +
              String(dateEst.getDate()).padStart(2, '0');
              data.item.IncTmpEst = dateStringEst;
            }

            if(data.item.IncTmpComp != null)
            {
              const dateComp = new Date(data.item.IncTmpComp);
              const dateStringComp = dateComp.getFullYear() + '-' + 
              String(dateComp.getMonth() + 1).padStart(2, '0') + '-' +
              String(dateComp.getDate()).padStart(2, '0');
              data.item.IncTmpComp = dateStringComp;
            }

            this.inc = data.item;

            // Init. tipo
            if(this.inc.IncCat != null && this.inc.IncCat.IncCatParent != null) {
              this.inc.Tipo = this.inc.IncCat.IncCatParent;
            }
        
            this.dataRows = this.inc.IncHis; // Historico de incidencias
            this.dataRowsMaterial = data.item.IncArticulos; // Materiales/conceptos
            delete this.inc.IncArticulos;
            this.ultimaRespuesta = this.inc.IncRespuesta;
          });
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.isValid = this.validate();
          if(!this.isValid) { return; }

          this.postDataToApi().then(data => {
            if (data.item.Success) {
              this.goToList();
            }
            else {
              alert(data.item.Message);
            }
          });
      },
      //#endregion

      //#region Click borrar
      onBtnDelete() {
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal() {
        this.showDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      //#endregion

      //#region Materiales/conceptos
      onBtnShowEditMaterial(item, isNew = false)
      {
        this.materialDialogDeleteMode = false;
        if (!isNew)
        {
          this.materialSelected.index = this.dataRowsMaterial.indexOf(item);
          this.materialSelected.tipo = item.Tipo;
          this.materialSelected.mat = { ArtId: item.ArticuloId, ArtNom: item.ArticuloNombre };
          this.materialSelected.observaciones = item.Observaciones;
          this.materialSelected.item = item;
          this.materialSelected.cantidad = item.Cantidad;
          this.materialSelected.importe = item.Importe;
        }
        else
        {
          this.materialSelected.index = -1;
          this.materialSelected.tipo = this.isProveedor ? 1 : null;
          this.materialSelected.mat = null;
          this.materialSelected.observaciones = '';
          this.materialSelected.item = {};
          this.materialSelected.cantidad = 0;
          this.materialSelected.importe = 0;
        }
        this.showMaterialDialog = true;
      },
      onBtnShowDeleteMaterial(item)
      {
        this.materialDialogDeleteMode = true;
        this.materialSelected.index = this.dataRowsMaterial.indexOf(item);
        this.materialSelected.item = item;
        this.showMaterialDialog = true;
      },
      onBtnCancelMaterialDialog()
      {
        this.showMaterialDialog = false;
        this.materialSelected.index = -1;
        this.materialSelected.tipo = null;
        this.materialSelected.mat = null;
        this.materialSelected.observaciones = '';
        this.materialSelected.item = null;
        this.materialSelected.cantidad = null;
        this.materialSelected.importe = null;
      },
      onBtnSaveMaterial()
      {
        // Se rellenan los campos
        this.materialSelected.item.Tipo = this.materialSelected.tipo;
        if (this.materialSelected.item.Tipo == 0)
        {
          this.materialSelected.item.ArticuloId = this.materialSelected.mat.ArtId;
          this.materialSelected.item.ArticuloNombre = this.materialSelected.mat.ArtNom;
          this.materialSelected.item.Observaciones = '';
        }
        else
        {
          this.materialSelected.item.ArticuloId = null;
          this.materialSelected.item.ArticuloNombre = '';
          this.materialSelected.item.Observaciones = this.materialSelected.observaciones;
        }
        this.materialSelected.item.Cantidad = this.materialSelected.cantidad;
        this.materialSelected.item.Importe = this.materialSelected.importe;

        // Se edita la lista
        if (this.materialSelected.index > -1)
        {
          this.dataRowsMaterial[this.materialSelected.index] = this.materialSelected.item
        }
        else
        {
          this.dataRowsMaterial.push(this.materialSelected.item);
        }

        // Se cierra el modal
        this.onBtnCancelMaterialDialog();
      },
      onBtnDeleteMaterial()
      {
        this.dataRowsMaterial.splice(this.materialSelected.index, 1);
        this.onBtnCancelMaterialDialog();
      },
      //#endregion

      //#region Validar formulario
      validate(validateForm = true) {
          if (validateForm) {
            this.$refs.form.validate();
          }
          if (this.inc == null) { return false; }
          if (this.inc.Dir == null || this.inc.Dir.DirId == null) { return false; }
          if (this.inc.Per == null || this.inc.Per.PerId == null) { return false; }
          if (this.inc.IncCat == null || this.inc.IncCat.IncCatId == null) { return false; }
          if (this.inc.Prior == null || this.inc.Prior.PriorId == null) { return false; }
          if (this.inc.IncDescrip == null || this.inc.IncDescrip == '') { return false; }
          return true;
      },
      //#endregion
      onChangeTipo() {
          this.inc.IncCat = null; 
          this.$forceUpdate();
      },
      onChangeOrden() {
        if (this.inc.IncPriorOrden == null || this.inc.IncPriorOrden === '') { return; }
        this.inc.IncPriorOrden = Math.trunc(this.inc.IncPriorOrden);
      },
      getTipoName(value)
      {
        try {
          return this.tipoOptions.find(f => f.value == value).label;
        }
        catch {
          return '';
        }
      },
      formatDate (value) {
        if(value == null) { return ''; }
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear() + 
            ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
      }
    } 
})
</script>
